
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";

import { useToast } from "vue-toastification";
import { ref } from "yup";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-11",
  components: {},
  props: {
    widgetClasses: String,
  },

  data() {
    return {

    // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,
    // end loading overlay setting

      tableData: [],
      paging:{
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      name: "",
      showFilter : false,
      timer: 0,
    }
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page){
      // loading overlay tampil
      this.isLoading = true;
      const toast = useToast();

      let search = '';
      if (this.name) {
        search = 'name=' + this.name + '&'; 
      } else {
        search
      }

      ApiService.getWithoutSlug('crmv2/main_user/marketing/all?' + search + '&' + 'page=' + page + '&size=' + size + '&sort=name&dir=1')
        .then(({ data }) => {
          this.tableData = data.content
          this.paging.page = data.page
          this.paging.size =  data.size
          this.paging.totalElements = data.totalElements
          this.paging.totalPages = data.totalPages

          this.isLoading = false;
        })
        .catch(({ e }) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            this.$router.push('/sign-in');
            this.isLoading = false;
          } else {
            toast.error(e.response.data.detail);
            this.isLoading = false;
          }
        });
    },

    handleDelete(index, item) {
      Swal
        .fire({
          title: "Apakah Anda Yakin ?",
          text: `Data yang telah dihapus tidak dapat dikembalikan !`,
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn btn-success btn-fill",
          cancelButtonClass: "btn btn-danger btn-fill",
          confirmButtonText: "Ya, hapus !",
          cancelButtonText: "Batal",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.deleteRow(item);
          }
        });
    },
    deleteRow(item) {

      const toast = useToast();

      ApiService
        .delete('crmv2/main_user/cs/office/' + item._id)
        .then((res) => {
          
          toast.success("Hapus Berhasil")

          this.getData(this.paging.size, this.paging.page);
        })

        .catch((error) => {

          toast.error("Gagal Hapus");

        });
    },

    range(min, max) {
      const arr: any[] = []
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },

    //event
    eventDelaySearch(e) {
      clearTimeout(this.timer) //this timer 0 - set 0 atau clear jika ada input lagi

      this.timer = setTimeout(() => {
          const page = this.paging.page;
          this.getData(this.paging.size, page);
        }, 1000)
    },
    showHideFilter(){
      this.showFilter = !this.showFilter;
    },
  },

});
